import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { window } from "browser-monads"
import ImageZoom from "../components/zoomable-gatsby-image"
import "./materials.scss"
import Navbar from "../components/navbar"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import ShowMoreText from 'react-show-more-text'
import OrderForm from "../components/order-form-hallways"

const HallwaysExamplesPage = props => {

  const hallwaysContent = props.data.wpgraphql

  return (
    <Layout>
      <SEO title="Прихожие" />
      <Navbar />
      <h1 className="text-center h4">Прихожие</h1>
      <p className="font-weight-normal text-center">
        <small>Все работы выполняются по индивидуальному заказу.</small>
      </p>
      <Container>
        <div className="card-group vgr-cards">
          {hallwaysContent.hallways.edges.map(edge => (
            <div className="card py-3" key={edge.node.id}>
              <div className="card-img-body" style={{ maxHeight: '500px', }}>
                <ImageZoom fluid={edge.node.hallwaysFields.photo.imageFile.childImageSharp.fluid} />
              </div>
              <div className="card-body">
                <h4 className="card-title h4 w-75">{edge.node.hallwaysFields.title}</h4>
                <ShowMoreText
                  /* Default options */
                  lines={5}
                  more='Читать дальше'
                  less='Скрыть текст'
                  anchorClass='text-dark underline-text'
                  expanded={false}
                  width='300'
                >{edge.node.hallwaysFields.description}</ShowMoreText>

              </div>
            </div>
          ))}
        </div>
        <h2 className="text-center order-form-heading">
          Сделать заказ
      </h2>
        <Row className="justify-content-center pb-5">
          <OrderForm />
        </Row>
      </Container>
    </Layout>
  )
}

export default HallwaysExamplesPage

export const pageQuery = graphql`
query HallwaysQuery {
  wpgraphql {
    hallways(first:100) {
      edges {
        node {
          hallwaysFields {
            title
            description
            photo {
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                  }
                }
              }
              id
            }
          }
        }
      }
    }
  }
}
`