import React, { useState } from "react"
import { navigate } from "gatsby-link"
import Form from "react-bootstrap/Form"
import ym from "react-yandex-metrika"
import Button from "react-bootstrap/Button"
import InputMask from "react-input-mask"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function OrderForm() {
  const [state, setState] = useState("")

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    ym("reachGoal", "send")

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <>
      <Form
        className="px-2"
        netlify-honeypot="bot-field"
        name="hallways"
        method="POST"
        data-netlify="true"
        action="/hallways/"
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="formBasicName">
          <Form.Label className="font-weight-bold">Ваше Имя</Form.Label>
          <Form.Control
            type="text"
            name="name"
            onChange={handleChange}
            placeholder="ФИО"
            required
          />
        </Form.Group>
        <Form.Control type="hidden" name="form-name" value="contact" />
        <Form.Control
          type="hidden"
          name="bot-field"
          onChange={handleChange}
          hidden
        />

        <Form.Group controlId="formBasicEmail">
          <Form.Label className="font-weight-bold">Ваш Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            placeholder="Введите email"
            required
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label className="font-weight-bold">
            Ваш номер телефона
          </Form.Label>
          <InputMask
            className="form-control"
            name="phone"
            mask="+7 999 999 99 99"
            maskChar=" "
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="formBasicDescription">
          <Form.Label className="font-weight-bold">Описание заказа</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            onChange={handleChange}
            placeholder="Опишите заказ"
          />
        </Form.Group>
        <Form.Check
          className="mb-3"
          type="checkbox"
          name="video"
          onChange={handleChange}
          label={`Хочу обсудить заказ по видеоконференции`}
          value="Требуется видеоконференция"
        />
        <Button variant="dark" type="submit">
          Отправить
        </Button>
        <Form.Text className="text-muted">
          Нажимая кнопку Отправить Вы соглашаетесь с <a
            target="_blank"
            className="mr-3 d-inline-block text-dark"
            href="../../documents/politikaconfimperia.pdf"
          >
            <u>
              Политикой конфиденциальности
                </u>
          </a>
          <br />
          <a
            target="_blank"
            className="mr-3 d-inline-block text-dark"
            href="../../documents/imperia_company_info.pdf"
          >
            <u>
              Реквизиты компании
                </u>
          </a>
        </Form.Text>
      </Form>
    </>
  )
}
